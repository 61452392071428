import { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Base } from '../../components/Base/Base';

import './Card.scss';

interface CardProps {
  wrapperClass?: string;
}

@Component({ name: 'Card' })
export class Card extends Base<CardProps> implements CardProps {
  public $slots!: {
    heading: Array<VNode> | undefined;
    content: Array<VNode> | undefined;
    actions: Array<VNode> | undefined;
  };

  @Prop({ required: false })
  public wrapperClass?: string | undefined;

  public render(h: CreateElement): VNode {
    return (
      <div class={['lp-card', this.wrapperClass].filter((x) => !!x).join(' ')}>
        {this.renderHeading(h)}
        {this.renderContent(h)}
        {this.renderActions(h)}
      </div>
    );
  }

  private renderHeading(h: CreateElement): VNode | null {
    if (this.$slots && this.$slots.heading) {
      return <div class="lp-card-heading">{this.$slots.heading}</div>;
    }

    return null;
  }

  private renderContent(h: CreateElement): VNode | null {
    if (this.$slots && this.$slots.content) {
      return <div class="lp-card-content">{this.$slots.content}</div>;
    }

    return null;
  }

  private renderActions(h: CreateElement): VNode | null {
    if (this.$slots && this.$slots.actions) {
      return <div class="lp-card-actions">{this.$slots.actions}</div>;
    }

    return null;
  }
}
