import { FontFile } from './FontFile';
import { MessageCaps } from './MessageCaps';
import { Model } from './Model';
import { NamedSegment } from './NamedSegment';
import { SegmentsModel } from './SegmentsModel';
import { SegmentsModelJSON } from './SegmentsModel/SegmentsModelJSON';
import { UnsubscribeCategory } from './UnsubscribeCategory';

interface AccessKey {
  name: string;
  value: string;

  allowDevelMode: boolean;
  allowDataExport: boolean;
  allowContentReadonly: boolean;
  allowContentReadWrite: boolean;
  allowedAudienceProvider: string;
}

interface App extends Model {
  attributes: AppAttributes;
  isWhitelistedForFeature(featureName: string): boolean;
  getDefaultNotificationChannel(): NotificationChannel | undefined;
  addSavedSegment(name: string, segmentJSON: SegmentsModelJSON): void;
  getPurchaseEvent(): string;
}

interface NotificationChannel {
  attributes: {
    id: string;
    name: string;
  };
}

interface AppAttributes {
  id: string;
  name: string;
  unique_key: string;
  company: number;
  platforms: Array<string>;
  category?: string;
  androidNotificationChannels: Array<NotificationChannel>;
  defaultAndroidNotificationChannel: string | null;
  defaultWebsite?: string;
  namedSegments: Array<NamedSegment>;
  fontFiles: Array<FontFile>;
  unsubscribeCategories?: Array<UnsubscribeCategory>;
  companyAccountId: string;
  isDemo?: boolean;
  dau: number;
  appPermissions: { [key: string]: boolean };
  emailFromAddress?: string;
  emailFromName?: string;
  emailUserAttribute?: string;
  emailRecipientTemplate?: string;
  feedWebhook?: string;
  accessKeys: Array<AccessKey>;
  requirePurchaseReceipts?: boolean;
  currencyCode?: string;
  maxMessagesPerMinute?: number;
  messageCaps?: Array<MessageCaps>;
  trackUninstalls?: boolean;
  channelId?: string;
}

interface AppData {
  attributes: {
    id: number;
    eventNames: Array<string>;
    stateNames: Array<string>;
    attributeColumns: Array<string>;
    paramColumns: Array<string>;
    messageEvents: { [key: number]: Array<string> };
    latestImport: number;
    minIosEventsVersion: string;
    minAndroidEventsVersion: string;
    minOtherEventsVersion: string;
  };
}

interface AppCertInfo {
  googleApiKey?: string;
  xiaomiAppSecret?: string;
  iosProduction?: ApnCertInfo;
  iosSandbox?: ApnCertInfo;
  iosSigningKey?: ApnPrivateKeyInfo;
  huawei?: {
    appId: string;
    appSecret: string;
    receiptUrl: string;
  };
}

interface AppAuthKey {
  id: string;
  isValid: boolean;
  errorMessage?: string;
}

interface ApnCertInfo extends AppAuthKey {
  expires: number;
  hasCertificate: boolean;
  hasPassphrase: boolean;
  isPassphraseValid: boolean;
  isSandbox: boolean;
  isSandboxAndProd: boolean;
}

interface ApnPrivateKeyInfo extends AppAuthKey {
  bundleId: string;
  keyId: string;
  teamId: string;
  hasSigningKey: boolean;
  signingKeyEnabled: boolean;
}

interface AppsModel extends Model {
  attributes: {
    apps: Array<App>;
    appsById: { [key: string]: App };
  };
  getApp(id: string): App;
  currentApp(): App;
  createApp(companyId: number, name: string, category: string, platforms: Array<string>, success?: Function, error?: Function): void;
  deleteApp(appId: string, success?: Function, error?: Function): void;
  setPlatform(appId: string, platform: string, selected: boolean, success?: Function, error?: Function): void;
  saveSegment(appId: string, segmentName: string, segment: SegmentsModel, success?: Function, error?: Function): void;
  deleteSegment(appId: string, segmentName: string | null, success?: Function, error?: Function): void;
  deleteEventsAndStates(
    appId: string,
    eventIds: Array<number>,
    stateIds: Array<number>,
    minIosEventsVersion: string,
    minAndroidEventsVersion: string,
    minOtherEventsVersion: string,
    callback?: () => void,
    error?: () => void
  ): void;
  getPromLabels(issuer: string, callback?: Function, error?: Function): void;
  getAppsData(appId?: string): AppData;

  AccessKey(options: Partial<AccessKey>): AccessKey;
  issueKey(appId: string, keyType: string, success?: Function, error?: Function): void;
  revokeKey(appId: string, index: number, success?: Function, error?: Function): void;
  editKey(
    appId: string,
    index: number,
    name: string,
    allowDevelMode: boolean,
    allowDataExport: boolean,
    allowContentReadonly: boolean,
    allowContentReadWrite: boolean,
    allowedAudienceProvider: string,
    success?: Function
  ): void;

  getCertInfo(appId: string, success?: Function, error?: Function): void;
  editKeyPassphrase(
    appId: string,
    keyType: CertificateKeyType,
    passphrase: string,
    success?: Function,
    error?: Function
  ): void;
  editHuaweiCredentials(
    appId: string,
    keyType: CertificateKeyType,
    passphrase: string,
    uniqueId: string,
    success?: Function,
    error?: Function
  ): void;
  regenerateHuaweiReceiptUrl(appId: string, success?: Function, error?: Function): void;
  editIosSigningKeyCredentials(
      appId: string,
      keyType: CertificateKeyType,
      teamId: string,
      keyId: string,
      bundleId: string,
      success?: Function,
      error?: Function
  ): void;
  editIosAuthType(
      appId: string,
      keyType: string,
      signingKeyEnabled: boolean,
      success?: Function,
      error?: Function
  ): void;
  deleteKeyFile(appId: string, keyType: CertificateKeyType, success?: Function, error?: Function): void;

  MessageCaps: typeof MessageCaps;
  saveMessageCaps(appId: string, success?: Function, error?: Function): void;
  setMessagingFields(appId: string, fields: Record<string, string>, success?: Function, error?: Function): void;
  setFields(appId: string, fields: Record<string, string>, success?: Function, error?: Function): void;
  setPurchaseEvent(appId: string, purchaseEventName: string, callback?: () => void, error?: () => void): void;

  createFontFile(
    appId: string,
    name: string,
    fontFamily: string,
    url: string,
    success?: Function,
    error?: Function
  ): void;
  updateFontFile(
    appId: string,
    name: string,
    updatedName: string,
    updateFontFamily: string,
    updatedUrl: string,
    success?: Function,
    error?: Function
  ): void;
  deleteFontFile(appId: string, name: string, success?: Function, error?: Function): void;

  addUnsubscribeCategory(appId: string, name: string, description: string, success?: Function, error?: Function): void;
  editUnsubscribeCategory(
    appId: string,
    id: string,
    name: string,
    description: string,
    success?: Function,
    error?: Function
  ): void;
  deleteUnsubscribeCategory(appId: string, id: string, success?: Function, error?: Function): void;

  getExportInfo(appId: string, success?: (exportInfo: ExportInfo) => void, error?: Function): void;
  editExport(
    appId: string,
    changes: Partial<{ [key in keyof ExportInfo]: string | boolean }>,
    success?: Function,
    error?: Function
  ): void;

  fetchLogs(appId: string, options: { query: string}, success?: Function, error?: Function): void;
}

type CertificateKeyType = 'ios-signing-key' | 'google-api' | 'ios-production' | 'ios-sandbox' | 'xiaomi-app-secret' | 'huawei-app-credentials';

interface ExportInfo {
  compressData: boolean;
  disabledReason: string;
  exportDaily: boolean;
  exportFormat: string;
  lastExportStatus: string;
  lastExportTimestamp: number;
  s3AccessId: string;
  s3AccessKey: string;
  s3BucketName: string;
  s3ObjectPrefix: string;
}

declare global {
  interface Window {
    AppsModel: AppsModel;
  }
}

const AppsModel: AppsModel = window.AppsModel;

export type {
  AccessKey,
  ApnCertInfo,
  App,
  AppAttributes,
  AppAuthKey,
  AppCertInfo,
  ApnPrivateKeyInfo,
  CertificateKeyType,
  ExportInfo,
  NotificationChannel
};
export { AppsModel };
