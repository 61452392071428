class RpcConstants {
  static PARAM_ACTION_KEY: string = 'action';
  static PARAM_APP_ID_KEY: string = 'appId';
  static PARAM_CSRF_KEY: string = '_c';
  static PARAM_TAB_ID_KEY: string = '_t';
  static PARAM_EMAIL_KEY: string = 'email';
  static ACTION_GET_FILES: string = 'getFiles';
  static ACTION_UPLOAD_FILE_NAME: string = 'uploadFile';
}

export { RpcConstants };
