import { Hint } from 'leanplum-lib-ui';
import Vue, { VNode } from 'vue';
import { Component } from 'vue-property-decorator';

import 'normalize.css';
import './App.scss';

const LOGO = require('./static/img/logo.svg');

@Component({ name: 'App' })
export class App extends Vue {
  protected error: string | null = null;
  protected links = [
    { text: 'leanplum.com', link: 'https://www.leanplum.com' },
    { text: 'Terms of Service', link: 'https://www.leanplum.com/tos/' },
    { text: 'Privacy Policy', link: 'https://www.leanplum.com/privacy/' },
    { text: 'Contact', link: 'https://www.leanplum.com/contact-us/' }
  ];

  render(): VNode {
    return (
      <div id="app">
        <header>
          <div class="logo" domPropsInnerHTML={LOGO} />
        </header>
        <main role="main">
          <router-view onError={this.onError} />
        </main>
        <footer>
          {this.renderLinks()}
          {this.renderCopyright()}
        </footer>
        <Hint type="error" message={this.error || ''} position="top" timeout={0} onClose={this.onErrorClear} />
      </div>
    );
  }

  renderLinks(): VNode | null {
    if (this.links && this.links.length) {
      return (
        <ul class="link-list">
          {this.links.map((x) => (
            <li>
              <a href={x.link} target="_blank">
                {x.text}
              </a>
            </li>
          ))}
        </ul>
      );
    }

    return null;
  }

  renderCopyright(): VNode {
    return <div class="copyright">&copy; {new Date().getFullYear()} Leanplum</div>;
  }

  protected onError(e: Error): void {
    this.error = e.message;
  }

  protected onErrorClear(): void {
    this.error = null;
  }
}
