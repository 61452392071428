export function getNameAbbreviation(name: string): string {
  if (name) {
    return name.split(/\s+/)
      .map((x) => /[A-Za-z0-9]/.test(x[0]) ? x[0] : '')
      .filter((x) => Boolean(x))
      .slice(0, 2)
      .join('')
      .toUpperCase();
  }

  return '';
}
