import { ActionType } from './ActionType';
import { LocalMessageCaps } from './LocalMessageCaps';
import { Model } from './Model';
import { VariableModel } from './VariableModel';

interface ActionDefinition extends Model {
  attributes: {
    name: ActionType;
    kind: number | string;
    hasHtmlTemplate: boolean;
    args: Array<VariableModel>;
  };
  displayName(): string;
}

interface LegacyStudy {
  attributes: {
    variables: Array<VariableModel>;
    regions: Array<RegionModel>;
  };
}

interface RegionProps {
  name: string;
  lat?: number;
  lon?: number;
  radius?: number;
  proximity_uuid?: string;
  major?: number;
  minor?: number;
  max_distance?: number;
  notify_entry_state_on_display?: boolean;
}

interface DefaultsModel extends Model {
  attributes: {
    id: number;
    fromCode: LegacyStudy;
    overrides: LegacyStudy;
    regions: Array<RegionModel>;
    localMessageCaps?: Array<LocalMessageCaps>;
  };

  LocalMessageCaps: typeof LocalMessageCaps;
  getAvailableActionDefinitions(): Array<ActionDefinition>;
  saveLocalMessageCaps(appId: string, success?: Function, error?: Function): void;
  addRegion(props: RegionModelAttributes, success: () => void, error: () => void): void;
  editRegion(props: RegionModelAttributes, success: () => void, error: () => void): void;
  deleteRegion(name: string, success: () => void, error: () => void): void;
  Region: new (data: RegionProps) => RegionModel;
}

declare global {
  interface Window {
    DefaultsModel: DefaultsModel;
  }
}

const DefaultsModel: DefaultsModel = window.DefaultsModel;

interface RegionModelAttributes {
  name: string;
  lat?: number;
  lon?: number;
  radius?: number;
  proximityUuid?: string;
  major?: number;
  minor?: number;
  maxDistance?: number;
  notifyEntryStateOnDisplay?: boolean;
}

interface RegionModel extends Model {
  attributes: RegionModelAttributes;
}

export type { RegionModel, RegionModelAttributes, ActionDefinition };
export { DefaultsModel };
