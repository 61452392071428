export enum ActionType {
  PUSH_ACTION_TYPE = '__Push Notification',
  WEBHOOK_ACTION_TYPE = '__Webhook',
  EMAIL_ACTION_TYPE = '__Email',
  NEWSFEED_MESSAGE_ACTION_TYPE = '__Newsfeed Message',
  ALERT_ACTION_TYPE = 'Alert',
  CONFIRM_ACTION_TYPE = 'Confirm',
  INTERSTITIAL_ACTION_TYPE = 'Interstitial',
  WEB_INTERSTITIAL_ACTION_TYPE = 'Web Interstitial',
  CENTER_POPUP_ACTION_TYPE = 'Center Popup',
  PUSH_ASK_TO_ASK_ACTION_TYPE = 'Push Ask to Ask',
  LIFECYCLE_ACTION_TYPE = '__Lifecycle Campaign',
  FLOATING_INTERSTITIAL = 'Floating Interstitial',
  IMAGE_INTERSTITIAL = 'Image Interstitial',
  SATISFACTION_SURVEY = 'Satisfaction Survey',
  BANNER = 'Banner',
  OPEN_URL = 'Open URL',
  REGISTER_FOR_PUSH = 'Register For Push',
  REQUEST_APP_RATING = 'Request App Rating'
}
