import Axios, { AxiosResponse } from 'axios';

export const REQUEST_SERVICE = Symbol('Request service');

export class RequestService {
  public authToken: string = '';

  constructor(private wnd: Window) {
    this.authToken = new URLSearchParams(this.wnd.location.search).get('token') || '';
  }

  // tslint:disable-next-line:no-any
  public request(method: string, data?: any): Promise<any> {
    return Axios.post(`/sso/${method}?token=${this.authToken}`, data || {}).then(
      (response: AxiosResponse) => response.data
    );
  }
}
