import { Model } from './Model';

/* tslint:disable:no-any */
declare class AccountModel implements Model {
  attributes: {
    name: string | null;
  };
  constructor(data: Object);
  get(): (key: string) => any;
}
/* tslint:enable:no-any */

declare global {
  interface Window {
    AccountModel: new (data: Object) => AccountModel;
  }
}

type AccountModelAlias = AccountModel;
const AccountModelAlias: typeof AccountModel = window.AccountModel;

export { AccountModelAlias as AccountModel };
