import { AccountModel } from './AccountModel';
import { AppsModel } from './AppsModel';
import { Model } from './Model';

interface CompanyRole extends Model {
  attributes: {
    id: string;
    name: string;
    company: string;
    capabilities: Array<number>;
  };
}

interface Account extends Model {
  attributes: {
    id: string;
    name: string;
    email: string;
    role: string;
    canManageTeam: boolean;
  };
}

interface Company extends Model {
  attributes: {
    id: number;
    name: string;
    website: string;
    size: string;
    accounts: Array<Account>;
    companyRoles: Array<CompanyRole>;
    enabledForEmail: boolean;
  };
}

interface CompanyModel extends Model {
  attributes: {
    companies: Array<Company>;
    visibleCompanies: Array<Company>;
  };
  getCompaniesById(): { [key: number]: Company };
  getCompany(companyId: number): Company;
  getCurrentCompanyAccount(appsModel: AppsModel): AccountModel;
  renameCompanyIfNeeded(companyId: number, appName: string): void;
}

declare global {
  interface Window {
    CompanyModel: CompanyModel;
  }
}

const CompanyModel: CompanyModel = window.CompanyModel;

export type { Company, Account, CompanyRole };
export { CompanyModel };
