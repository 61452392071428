import { Dictionary } from 'leanplum-lib-common';
import { AppsModel } from './models/AppsModel';
import { DeviceModel } from './models/DeviceModel';
import { BaseModel } from './models/BaseModel';
import { CompanyModel } from './models/CompanyModel';
import { FilesModel } from './models/FilesModel';
import { Model } from './models/Model';
import { RpcConstants } from './models/RpcConstants';

class LegacyUtils {
  static getAppsModelInstance(): Promise<AppsModel> {
    return LegacyUtils.getLegacyModelInstance(AppsModel);
  }

  static getCompanyModelInstance(): Promise<CompanyModel> {
    return LegacyUtils.getLegacyModelInstance(CompanyModel);
  }

  static getDeviceModelInstance(): Promise<DeviceModel> {
    return LegacyUtils.getLegacyModelInstance(DeviceModel);
  }

  static getFilesModelInstance(): Promise<FilesModel> {
    return LegacyUtils.getLegacyModelInstance(FilesModel);
  }

  static getLegacyModelInstance<T extends Model>(model: T): Promise<T> {
    return new Promise<T>(
      (resolve: (modelInstance: T) => void): void => {
        BaseModel.getInstance(model, (modelInstance: T) => {
          resolve(modelInstance);
        });
      }
    );
  }

  static getFileUploadUrl(params: LegacyUtils.FileUploadParams): string {
    const paramsMap: Dictionary<string> = {
      [RpcConstants.PARAM_ACTION_KEY]: RpcConstants.ACTION_UPLOAD_FILE_NAME,
      [RpcConstants.PARAM_APP_ID_KEY]: params.appId,
      [RpcConstants.PARAM_CSRF_KEY]: params.csrfToken,
      [RpcConstants.PARAM_EMAIL_KEY]: params.email
    };

    const paramsArr = Object.keys(paramsMap).reduce((memo: Array<string>, key: string) => {
      memo.push(`${key}=${paramsMap[key]}`);

      return memo;
    }, []);

    return `${BaseModel.CONTENT_MANAGEMENT_URL_ROOT}?${paramsArr.join('&')}`;
  }
}

namespace LegacyUtils {
  export interface FileUploadParams {
    appId: string;
    csrfToken: string;
    email: string;
  }
}

export { LegacyUtils };
