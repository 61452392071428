/* tslint:disable:no-any */

import { Model } from './Model';

interface GetImageUrlOptions {
  useHttps?: boolean;
  fullSize?: boolean;
  maxSize?: number;
  crop?: boolean;
  useHtmlDefault?: boolean;
}

enum FileType {
  UNKNOWN_TYPE = 0,
  IMAGE_TYPE = 1
}

interface FileModelAttributes {
  name: string;
  url: string;
  image_url: string;
  filename: string;
  editable: boolean;
}

interface FileMetadata {
  fileName: string;
  fileType: FileType;
  imageUrl?: string;
}

declare class FileModel implements Model {
  attributes: {
    name: string;
    url: string;
    imageUrl: string;
    filename: string;
    editable: boolean;
    created_at: number;
    created_by: string;
  };
  constructor(data: FileModelAttributes);
  getImageUrl(options?: GetImageUrlOptions): string;
  getResolvedResourceUrl(includeNameParameter?: boolean): string;
  getType(): FileType;
  toMetadataString(): string;
}

interface FilesModel extends Model {
  attributes: {
    filesByName: { [key: string]: FileModel };
  };
  refresh(callback: () => void): void;
  deleteFiles(names: Array<string>, callback: () => void, error: (err: Error) => void): void;
  getFile(name: string): FileModel | undefined;
}

declare global {
  interface Window {
    FileModel: new (data?: FileModelAttributes) => FileModel;
    FilesModel: FilesModel;
  }
}

type FileModelAlias = FileModel;
const FileModelAlias: typeof FileModel = window.FileModel;

const FilesModel: FilesModel = window.FilesModel;

export type { FileMetadata, GetImageUrlOptions };
export { FileModelAlias as FileModel, FilesModel, FileType };
