import { ErrorCallback } from './BaseModel';
import { Model } from './Model';

interface ContentTemplatesModel extends Model {
  attributes: {
    contentTemplatesByName: { [key: string]: ContentTemplatesModel.ContentTemplate };
  };

  getContentTemplate(templateName: string): ContentTemplatesModel.ContentTemplate | undefined;

  createContentTemplate(
    name: string,
    text: string,
    model: string,
    kind: ContentTemplateKind,
    labels: Array<string>,
    success: ContentTemplatesModel.SuccessCallback,
    error: Function
  ): void;

  addContentTemplate(
    kind: ContentTemplateKind,
    callback?: ContentTemplatesModel.SuccessCallback,
    error?: Function
  ): void;

  editContentTemplate(
    originalName: string,
    templateAttributes: ContentTemplatesModel.Attributes,
    callback?: ContentTemplatesModel.SuccessCallback | Function,
    error?: ErrorCallback | Function
  ): void;

  deleteContentTemplate(templateName: string, callback: () => void, error: ErrorCallback | Function): void;

  getTemplates(kind: ContentTemplateKind): Array<ContentTemplatesModel.ContentTemplate>;
  getHtmlTemplates(): Array<ContentTemplatesModel.ContentTemplate>;
  getModelTemplates(): Array<ContentTemplatesModel.ContentTemplate>;
}

enum ContentTemplateKind {
  HTML = 0,
  MODEL = 1
}

namespace ContentTemplatesModel {
  export interface Attributes {
    name: string;
    text: string;
    kind: ContentTemplateKind;
    editorModel?: string;
    description?: string;
    created?: string;
    createdByName?: string;
    lastChangeTime?: string;
    lastChangeByName?: string;
    referenceCount?: number;
    isReadOnly?: boolean;
    labels?: Array<string>;
  }

  export interface ContentTemplate extends Model {
    attributes: Attributes;
  }

  export type SuccessCallback = (template: ContentTemplate) => void;
}

declare global {
  interface Window {
    ContentTemplatesModel: ContentTemplatesModel;
  }
}

const ContentTemplatesModel: ContentTemplatesModel = window.ContentTemplatesModel;

export { ContentTemplatesModel, ContentTemplateKind };
