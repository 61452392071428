import { cx, ListView } from 'leanplum-lib-ui';
import { SelectedSsoMode, SsoConfig } from 'leanplum-proto-sso';
import { VNode } from 'vue';
import { Component, Inject } from 'vue-property-decorator';
import { REQUEST_SERVICE, RequestService } from '../../api/RequestService';
import { Base } from '../../components/Base/Base';
import { Card } from '../../components/Card/Card';
import { getNameAbbreviation } from '../../utils/getNameAbbreviation';

import LOCK_ICON from '../../static/img/locked-10.svg';
import './CompanyList.scss';

interface CompanyInfo {
  name: string;
  isLocked: boolean;
  url: string;
}

@Component({ name: 'CompanyList' })
export class CompanyList extends Base<{}> {
  protected companies: Array<CompanyInfo> | null = null;

  @Inject(REQUEST_SERVICE) protected readonly requestService!: RequestService;

  public mounted(): void {
    this.requestService
      .request('companies')
      .then((response: { companies: Array<SsoConfig> }) => {
        this.companies = response.companies.map((x) => ({
          name: x.companyName,
          isLocked: x.hasConfiguredSso && x.mode === SelectedSsoMode.SsoMode.SSO_ONLY,
          url: x.idp
        }));
      })
      .catch(this.onError.bind(this));
  }

  public render(): VNode {
    return (
      <div class="company-list-component">
        <Card>
          <template slot="heading">Choose a company to continue</template>
          <template slot="content">{this.renderCompanies()}</template>
        </Card>
      </div>
    );
  }

  protected renderCompanies(): VNode {
    if (this.companies) {
      if (this.companies.length > 0) {
        return (
          <ListView<CompanyInfo>
            class="company-list"
            items={this.companies}
            onClick={this.onItemClicked}
            scopedSlots={{
              item: (item: CompanyInfo) => this.renderCompany(item)
            }}
          />
        );
      } else {
        return <p class="no-results">There aren't any SSO companies.</p>;
      }
    } else {
      return <p>Loading, please wait...</p>;
    }
  }

  protected renderCompany(info: CompanyInfo): VNode {
    return (
      <div class="company-list-item">
        <div class="item-icon">
          {info.isLocked ? <i domPropsInnerHTML={LOCK_ICON} /> : <span>{getNameAbbreviation(info.name)}</span>}
        </div>
        <div class={cx('item-name', { 'item-locked': info.isLocked })}>{info.name}</div>
      </div>
    );
  }

  protected onError(e: Error): void {
    this.$emit('error', e);
  }

  protected onItemClicked(item: CompanyInfo): void {
    if (item.url) {
      window.location.assign(item.url);
    }
  }
}
