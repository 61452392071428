/* istanbul ignore file */
import { Buffer } from 'buffer';
import 'reflect-metadata';

import Axios from 'axios';
import Vue from 'vue';
import { REQUEST_SERVICE, RequestService } from './api/RequestService';
import { App } from './App';
import { router } from './router';

Vue.config.productionTip = false;

// fix for webpack 5 not polyfilling Node types
window.Buffer = Buffer;

new Vue({
  provide: {
    axios: Axios,
    window,
    [REQUEST_SERVICE]: new RequestService(window)
  },
  router,
  render: (h) => h(App)
}).$mount('#root');
