import { injectable } from 'inversify';
import { LegacyUtils } from '../../LegacyUtils';
import { DefaultsModel } from '../../models/DefaultsModel';
import { DefaultsModelProvider } from './DefaultsModelProvider';

@injectable()
export class StaticDefaultsModelProvider implements DefaultsModelProvider {
  private model: Promise<DefaultsModel>;

  constructor(model?: DefaultsModel) {
    this.model = model ? Promise.resolve(model) : LegacyUtils.getLegacyModelInstance(DefaultsModel);
  }

  public async getDefaultsModel(): Promise<DefaultsModel> {
    return this.model;
  }
}
