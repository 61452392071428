import { Model } from './Model';

interface DeviceModel extends Model {
  attributes: {
    appDevicesById: { [key: string]: DeviceModel.User };
    accountDevicesById: { [key: string]: DeviceModel.Device };
    devices: Array<DeviceModel.Device>;
    unregisteredDevices: Array<DeviceModel.Device>;
  };
  refresh(onSuccess?: () => void, onError?: (error: Error) => void): void;
  registerDevice(deviceId: string, onSuccess?: () => void, onError?: (error: Error) => void): void;
  forgetDevice(deviceId: string, deviceEmail?: string, onSuccess?: () => void, onError?: (error: Error) => void): void;
}

namespace DeviceModel {
  export interface User {
    attributes: {
      id: string;
      userId: string;
      devices: Array<Device>;
    };
  }

  export interface Device {
    attributes: {
      unique_id: string;
      name: string;
      model: string;
      system_name: string;
      system_version: string;
      lastActive: number;
    };
  }
}

declare global {
  interface Window {
    DeviceModel: DeviceModel;
  }
}

const DeviceModel: DeviceModel = window.DeviceModel;

export { DeviceModel };
