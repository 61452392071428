/* tslint:disable:no-any */

import { SegmentEvent } from 'leanplum-lib-common';
import { Model } from './Model';
import { SegmentCategory } from './SegmentsModel/SegmentCategory';
import { SegmentKind } from './SegmentsModel/SegmentKind';
import { SegmentsModelJSON } from './SegmentsModel/SegmentsModelJSON';

declare class SegmentsModel implements Model {
  attributes: {
    children: Array<SegmentsModel>;
    subject: string;
    type: SegmentCategory | SegmentKind;
  };
  constructor(data?: SegmentsModelJSON, type?: SegmentCategory | SegmentKind);
  get(key: string): any;
  isEmpty(): boolean;
  isValid(): boolean;
  groupSummary(getMessagingEvents?: () => Array<SegmentEvent>): string;
  summary(getMessagingEvents?: () => Array<SegmentEvent>): string;
  encode(callback: (text: string, err: Error) => void): void;
  toJSON(): SegmentsModelJSON;
}

declare global {
  interface Window {
    SegmentsModel: new (data?: SegmentsModelJSON, type?: SegmentCategory | SegmentKind) => SegmentsModel;
  }
}

type SegmentsModelAlias = SegmentsModel;
const SegmentsModelAlias: typeof SegmentsModel = window.SegmentsModel;

// :KLUDGE: Trying to export `SegmentsModel` directly doesn't work, because only the type is
// exported, not the value.  Manually aliasing and re-exporting both the type and the value works.
export { SegmentsModelAlias as SegmentsModel };
