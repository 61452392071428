/* tslint:disable:no-any */

import { Model } from './Model';

interface BaseModel {
  CONTENT_MANAGEMENT_URL_ROOT: string;
  REPORTS_URL_ROOT: string;
  SEGMENTATION_URL_ROOT: string;
  SETTINGS_URL_ROOT: string;
  getInstance: (modelClass: Model, callback: (arg: Model) => any) => void;
}

type ErrorCallback = () => { message: string };

declare global {
  interface Window {
    BaseModel: BaseModel;
  }
}

const BaseModel: BaseModel = window.BaseModel;

export type { ErrorCallback };
export { BaseModel };
