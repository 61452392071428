import { AxiosResponse, AxiosStatic } from 'axios';
import IsEmail from 'isemail';
import { Button, Icon, StargateTarget, WrappedTextInput } from 'leanplum-lib-ui';
import { LoginRequest, LoginResponse } from 'leanplum-proto-sso';
import Vue, { VNode } from 'vue';
import { Component, Inject } from 'vue-property-decorator';
import { Card } from '../../components/Card/Card';

import './LoginForm.scss';

enum LoginStep {
  EMAIL,
  PASSWORD
}

@Component({ name: 'LoginForm' })
export class LoginForm extends Vue {
  private static BACKEND_ENDPOINT = '/login';
  private static CLASSIC_LOGIN_URL = 'https://www.leanplum.com/dashboard/access?continue=%2Fdashboard#login';

  private email: string = '';
  private password: string = '';
  private isProcessing: boolean = false;
  private step: LoginStep = LoginStep.EMAIL;

  protected error: string = '';

  protected get isEmailValid(): boolean {
    return !!this.email && IsEmail.validate(this.email);
  }

  @Inject('window') private wnd: Window;
  @Inject('axios') private axios: AxiosStatic;

  public render(): VNode {
    return (
      // NOTE: Disabled the browser validation in favor of the custom one.
      <form class="login-form" onSubmit={this.onFormSubmit} novalidate={true}>
        <Card>
          <template slot="heading">
            {this.step === LoginStep.EMAIL && (
              <div class="lp-login-title">
                <span class="welcome">Welcome to Leanplum!</span>
              </div>
            )}
            {this.step === LoginStep.PASSWORD && (
              <div class="lp-login-title">
                <span class="welcome">Welcome to Leanplum, </span>
                <span class="email">{this.email}</span>
              </div>
            )}
          </template>
          <template slot="content">
            {this.step === LoginStep.EMAIL && (
              <WrappedTextInput
                key="email"
                class="full-width lp-login-email"
                label="Enter your email"
                placeholder=" "
                autoFocus={true}
                required={true}
                error={this.error}
                value={this.email}
                onInput={this.onEmailChange}
              />
            )}
            {this.step === LoginStep.PASSWORD && (
              <WrappedTextInput
                key="password"
                class="full-width lp-login-password"
                type={WrappedTextInput.Type.PASSWORD}
                label="Enter your password"
                placeholder=" "
                autoFocus={true}
                required={true}
                error={this.error}
                v-model={this.password}
              >
                <template slot="after">
                  <a
                    class="forgot-password"
                    target="_blank"
                    href="https://www.leanplum.com/dashboard/access?continue=%2Fdashboard#reset"
                  >
                    Forgot?
                  </a>
                </template>
              </WrappedTextInput>
            )}
          </template>
          <template slot="actions">
            <Button text="Next" type="submit" color="primary" disabled={this.isProcessing} />
            {this.step === LoginStep.EMAIL && (
              <Button
                type="button"
                text="Use Leanplum Credentials"
                onClick={this.useClassicLogin}
                appearance={Button.Appearance.OUTLINE}
              />
            )}
            {this.step === LoginStep.PASSWORD && (
              <Button
                type="button"
                text="Use another email"
                icon={Icon.Type.ARROW_LEFT}
                class="change-email"
                onClick={this.onNavigateToEmail}
                appearance={Button.Appearance.OUTLINE}
              />
            )}
          </template>
        </Card>
        <StargateTarget />
      </form>
    );
  }

  public onFormSubmit(e: Event): Promise<void | AxiosResponse> {
    e.preventDefault();

    this.error = '';

    if (this.step === LoginStep.EMAIL && !this.isEmailValid) {
      this.error = 'Please enter a valid email.';

      return Promise.reject(this.error);
    }

    if (this.step === LoginStep.PASSWORD && !this.password) {
      this.error = 'Please enter a password.';

      return Promise.reject(this.error);
    }

    const data: LoginRequest = new LoginRequest();
    data.email = this.email;

    if (this.step === LoginStep.PASSWORD) {
      data.password = this.password;
    }

    this.isProcessing = true;

    return this.axios
      .post(LoginForm.BACKEND_ENDPOINT, data.toJSON())
      .then(this.onLoginResponse.bind(this))
      .catch(this.onLoginError.bind(this));
  }

  public onNavigateToEmail(): void {
    this.password = '';
    this.step = LoginStep.EMAIL;
  }

  private useClassicLogin(): void {
    this.wnd.location.href = LoginForm.CLASSIC_LOGIN_URL;
  }

  private onLoginResponse(response: AxiosResponse): AxiosResponse {
    if (typeof response.data === 'string') {
      // handle direct redirects
      this.wnd.location.href = response.data;
    } else {
      const data = response.data as LoginResponse;

      if (data.redirectTo) {
        this.wnd.location.href = data.redirectTo;
      } else if (data.passwordRequired) {
        this.step = LoginStep.PASSWORD;
      } else if (data.error) {
        this.error = data.error;
      }
    }

    this.isProcessing = false;

    return response;
  }

  private onLoginError(): void {
    this.$emit(
      'error',
      new Error('Single sign-on has failed. Please try again. If the problem persists, contact your administrator.')
    );
    this.isProcessing = false;
  }

  private onEmailChange(value: string): void {
    this.email = (value || '').trim();
  }
}
