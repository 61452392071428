enum SegmentCategory {
  SEGMENT_CATEGORY_EVENTS = 0,
  SEGMENT_CATEGORY_LOCAL_TRIGGERS = 1,
  SEGMENT_CATEGORY_SERVER_TRIGGERS = 2,
  SEGMENT_CATEGORY_LOCAL_LIMITS = 3,
  SEGMENT_CATEGORY_SERVER_LIMITS = 4,
  SEGMENT_CATEGORY_IMPRESSION = 5,
  SEGMENT_CATEGORY_MESSAGE_GOALS = 6,
  SEGMENT_CATEGORY_RECURRING_PATTERNS = 7,
  SEGMENT_CATEGORY_NONE = 8,
  SEGMENT_CATEGORY_LP2_EVENTS = 9,
  SEGMENT_CATEGORY_LP2_SERVER_TRIGGERS = 10,
  SEGMENT_CATEGORY_LP2_SERVER_TRIGGERS_WITH_REGION = 11
}

export { SegmentCategory };
