/* istanbul ignore file */

import Vue from 'vue';
import VueRouter from 'vue-router';
import { CompanyList } from './views/CompanyList/CompanyList';
import { LoginForm } from './views/LoginForm/LoginForm';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/companies',
      name: 'companies',
      component: CompanyList
    },
    {
      path: '/login',
      name: 'login',
      component: LoginForm
    },
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '*',
      redirect: '/login'
    }
  ]
});
