import { SegmentEvent } from 'leanplum-lib-common';
import { AppsModel } from './AppsModel';
import { DefaultsModel } from './DefaultsModel';
import { SegmentsModel } from './SegmentsModel';
import { SegmentsControllerOptions } from './SegmentsModel/SegmentsControllerOptions';

declare class SegmentsController {
  model: SegmentsModel;
  options: SegmentsControllerOptions;

  constructor(
    segmentsModel: SegmentsModel,
    appsModel: AppsModel,
    experimentsModel: object | undefined,
    view: HTMLElement,
    options: SegmentsControllerOptions,
    defaultsModel: DefaultsModel,
    getMessagingEvents: () => Array<SegmentEvent>
  );
  render(): void;
  reRender(model: SegmentsModel, options: SegmentsControllerOptions): void;
  on(type: string, callback: Function): void;
  off(type: string, callback?: Function): void;
  destroyTypeahead(): void;
  destroy(): void;
}

declare global {
  interface Window {
    SegmentsController: new (...args: Array<never>) => SegmentsController;
  }
}

type SegmentsControllerAlias = SegmentsController;
const SegmentsControllerAlias: typeof SegmentsController = window.SegmentsController;

// :KLUDGE: Trying to export `SegmentsController` directly doesn't work, because only the type is
// exported, not the value.  Manually aliasing and re-exporting both the type and the value works.
export { SegmentsControllerAlias as SegmentsController };
